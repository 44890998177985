import { qspost } from '@/config/axios';

//获取专题数据
export const getList = data => qspost("request?", data, "videosEdition");

//获取视频列表
export const getVideoArr = data => qspost("request?", data, "getVideoTitle");

//获取视频分类
export const getType = data => qspost("request?",data,"videosType");

//新增专题
export const add = data => qspost("request?", data, "addVideosEdition");

//编辑专题
export const edit = data => qspost("request?", data, "updateVideosEdition");

//删除专题
export const del = data => qspost("request?", data, "deleteVideosEdition");

//冻结*解冻
export const freezeThaw = data => qspost("request?", data, "lockVideosEdition");

//获取专题已有视频数据
export const getVideo = data => qspost("request?", data, "getEditionVideo");

//修改专题数据
export const editVideo = data => qspost("request?", data, "addEditionVideo");

//获取专题下已有视频数据
export const getVideoEdition = data => qspost("request?", data, "getVideoEdition");

//移除专题下已有视频数据
export const removeEditionVideo = data => qspost("request?", data, "removeEditionVideo");

//获取专题分类
export const getSubjectType = data => qspost("request?", data, "getSelectList");