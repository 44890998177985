<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        分类: <el-select v-model="searchArr.type" filterable placeholder="全部" size="medium" clearable >
        <el-option label="全部" :value=-1 ></el-option>
        <el-option label="未分类" :value=0 ></el-option>
        <el-option v-for="item in subjectType" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
        状态: <el-select v-model="searchArr.status" filterable placeholder="全部" size="medium" clearable >
        <el-option label="全部" :value=-1 ></el-option>
        <el-option label="未启用" :value=0 ></el-option>
        <el-option label="启用" :value=1 ></el-option>
      </el-select>
        <el-button type="primary" size="medium" @click="dialogAdd = true;title = '添加专题'">添加专题</el-button>
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" label="专题名称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="分类" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="banner" label="专题图片" align="center" :show-overflow-tooltip="true">
        <template v-slot="scope">
          <div class="big" @click="amplification(scope.row.banner)">{{scope.row.banner}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="counter" label="总作品数" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div @click="getVideoEdition(scope.row)" class="mouse">{{scope.row.counter}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="enable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{color: scope.row.enable == '启用' ? '#33C933' : '#F33333' }">{{scope.row.enable}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="editor(scope.row)">编辑</span>
          <span class="operation" @click="getVideo(scope.row)">添加视频</span>
          <span class="operation" @click="freezeThaw(scope.row,scope.row.enable == '启用' ? '禁用' : '启用')">{{scope.row.enable == '启用' ? '禁用' : '启用'}}</span>
          <span class="operation" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
    <!-- 添加修改专题弹窗 -->
    <el-dialog :title="title" v-model="dialogAdd" width="492px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">专题名称：</div>
        <el-input v-model="dialogArr.title" placeholder="专题名称" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">精选分类：</div>
        <el-select v-model="dialogArr.type_id" placeholder="无分类" size="medium">
          <el-option label="未分类" :value="0"></el-option>
          <el-option v-for="item in subjectType" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="form_box">
        <div class="form_title">权重：</div>
        <el-input v-model="dialogArr.weight" placeholder="权重" size="small" type="number"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">专题介绍：</div>
        <el-input v-model="dialogArr.description" placeholder="不得超过100字" size="small" type="textarea" maxlength="100" show-word-limit :rows="7" resize="none">
        </el-input>
      </div>
      <div class="form_box">
        <div class="form_title">专题宣传图：</div>
        <el-upload class="avatar-uploader" action="#" accept="image/*" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="uploadIcon">
          <img v-if="dialogArr.iconUrl" :src="dialogArr.iconUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div class="note">支持扩展名:.png .jpg</div>
      </div>
      <div class="form_box">
        <div class="form_title">专题图标：</div>
        <el-upload class="avatar-uploader" action="#" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="upload">
          <img v-if="dialogArr.imgUrl" :src="dialogArr.imgUrl" style="border-radius: 60px;" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div class="note">支持扩展名:.png .jpg</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="add" v-if="title == '添加专题'">确认添加</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 专题添加视频 -->
    <el-dialog :title="videoTitle" v-model="dialogVideo" width="1180px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="search">
        <div class="search-box">
          <span class="search-box-title">分类：</span>
          <el-select v-model="type" filterable placeholder="全部" size="medium" clearable @change="changeType">
            <el-option label="全部" value=""></el-option>
            <el-option label="未分类" value="未分类"></el-option>
            <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.label"></el-option>
          </el-select>
        </div>
      </div>
      <div style="padding-bottom:60px">
        <div style="width:700px;display:inline-block;">
          <el-table :data="videoList" :header-cell-style="{ background: '#F7F8FA' }" height="538">
            <el-table-column prop="id" label="ID" width="100px" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column width="200px" align="center">
              <template #header>
                <el-input v-model="searchVideo" placeholder="输入视频标题搜索" size="small" />
              </template>
              <template #default="scope">
                <span class="operation" v-if="!scope.row.checked" @click="addVideoList(scope.row)">添加</span>
                <span v-else style="color:#ccc">已添加</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="selecteds">
          <div class="selectedsHerder">
            <span>已选择视频（{{videoId.length}}）</span>
            <span class="selectedsEmpty" @click="emptyVideoList()">清空</span>
          </div>
          <div class="selectedsList">
            <el-table :data="videoId" :show-header="false" :header-cell-style="{ background: '#F7F8FA' }" height="488">
              <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true">
                <template #default="scope">
                  <div>({{scope.row.id}}){{scope.row.title}}</div>
                </template>
              </el-table-column>
              <el-table-column width="70px" align="center">
                <template #default="scope">
                  <span class="operation" @click="delVideoList(scope.row)">移除</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pages.page"
          :page-sizes="[10, 20, 30, 50]" :page-size="pages.rows" background layout="total, sizes, prev, pager, next, jumper" :total="videoArr
        .filter((data) => !searchVideo || data.title.toLowerCase().includes( searchVideo.toLowerCase()))
        .filter(
          (data) =>
            !type ||
            data.type.toLowerCase().includes(type.toLowerCase())
        ).length">
        </el-pagination>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="editVideo">确定修改</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 专题已有视频显示表格 -->
    <el-dialog :title="videoArrTitle" v-model="videoArrDialog" width="1000px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
      <div style="padding-bottom:60px">
        <el-table :data="videoData.filter(data => !search || data.title.toLowerCase().includes(search.toLowerCase()))"
          :header-cell-style="{ background: '#F7F8FA' }">
          <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="type" label="所属分类" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="watch_count" label="观看次数" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="good_count" label="点赞次数" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column width="200px" align="center">
            <template #header>
              <el-input v-model="search" placeholder="输入视频标题搜索" />
            </template>
            <template #default="scope">
              <span class="operation" @click="removeEditionVideo(scope.row)">移除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <div>
      <el-image class="bigimg" style="width: 0.01px; height: 0.01" :src="src" :preview-src-list="srcList">
      </el-image>
    </div>
  </div>
</template>

<script>
import {
  getList,
  add,
  edit,
  del,
  getSubjectType,
  freezeThaw,
  getVideo,
  editVideo,
  getVideoArr,
  getVideoEdition,
  removeEditionVideo,
  getType,
} from "@/api/video/subject";
import { upload } from "@/api/common/common";
import { nextTick } from "vue";
import page from "@/components/page";
export default {
  name: "videoSubject",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      pages: { total: 0, page: 1, rows: 10 },
      tableData: [{}], //数据
      row: [], //选中的数据
      title: "", //弹窗标题
      dialogAdd: false,
      dialogArr: {
        title: "", //标题
        weight: 0, //权重
        type_id: 0, //专题分类
        description: "", //简介
        icon: "", //图标地址
        iconUrl: "", //图标完整地址
        url: "", //图片地址
        imgUrl: "", //图片完整地址
      },
      videoTitle: "", //添加视频弹窗标题
      dialogVideo: false, //添加视频弹窗
      videoId: [], //选中的视频id
      checked: 1, //
      videoArr: [], //视频列表
      src: "",
      srcList: [], //放大图片
      videoArrDialog: false, //已有视频弹出层
      videoArrTitle: "", //已有视频弹出层标题
      search: "",
      searchArr: {
        type:-1,
        status:-1,
      },
      videoData: [], //已有视频表格数据
      subject: [], //已选专题数据
      searchVideo: "", // 搜索视频
      typeArr: [], // 分类数组
      type: "", // 已选分类
      subjectType: [], // 专题分类
    };
  },
  computed: {
    videoList() {
      return this.videoArr
        .filter(
          (data) =>
            !this.searchVideo ||
            data.title.toLowerCase().includes(this.searchVideo.toLowerCase())
        )
        .filter(
          (data) =>
            !this.type ||
            data.type.toLowerCase().includes(this.type.toLowerCase())
        )
        .slice(
          (this.pages.page - 1) * this.pages.rows,
          this.pages.page * this.pages.rows
        );
    },
  },
  created() {},
  mounted() {
    this.getList();
    this.getType();
    this.getSubjectType();
    this.getVideoArr();
  },
  methods: {
    //获取专题数据
    getList() {
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "type", val: this.searchArr.type },
        { key: "status", val: this.searchArr.status },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取专题分类
    getSubjectType() {
      getSubjectType().then((res) => {
        if (res.code == 0) {
          this.subjectType = res.data;
        }
      });
    },
    //获取视频分类
    getType() {
      getType().then((res) => {
        if (res.code == 0) {
          this.typeArr = res.data;
        }
      });
    },
    //获取视频
    getVideoArr() {
      getVideoArr().then((res) => {
        if (res.code == 0) {
          this.videoArr = res.data;
          this.pages.total = res.data.length;
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },
    //新增专题
    add() {
      if (this.dialogArr.weight < 0)
        return this.$message({ message: "权重不能未负数", type: "error" });
      add([
        { key: "title", val: this.dialogArr.title },
        { key: "type_id", val: this.dialogArr.type_id },
        { key: "weight", val: this.dialogArr.weight },
        { key: "description", val: this.dialogArr.description },
        { key: "icon", val: this.dialogArr.icon },
        { key: "url", val: this.dialogArr.url },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.data, type: "success" });
            this.dialogAdd = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //把数据放入编辑内
    editor(row) {
      this.row = row;
      this.title = `编辑专题(ID：${row.id})`;
      this.dialogArr = {
        title: row.title, //标题
        type_id: row.type_id, //分类id
        weight: row.weight, //权重
        description: row.description, //简介
        icon: row.icon_, //图标地址
        iconUrl: row.icon, //图标完整地址
        url: row.banner_, //图片地址
        imgUrl: row.banner, //图片详细地址
      };
      this.dialogAdd = true;
    },
    //编辑专题
    edit() {
      if (this.dialogArr.weight < 0)
        return this.$message({ message: "权重不能未负数", type: "error" });
      edit([
        { key: "id", val: this.row.id },
        { key: "title", val: this.dialogArr.title },
        { key: "type_id", val: this.dialogArr.type_id },
        { key: "weight", val: this.dialogArr.weight },
        { key: "description", val: this.dialogArr.description },
        { key: "icon", val: this.dialogArr.icon },
        { key: "url", val: this.dialogArr.url },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.data, type: "success" });
            this.dialogAdd = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除专题
    del(row) {
      this.row = row;
      this.$confirm(
        "你确定要删除专题：" + row.title + "吗？此操作无法撤回,请谨慎操作",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          del([{ key: "id", val: this.row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //冻结*解冻
    freezeThaw(row, str) {
      this.row = row;
      this.$confirm(
        "你确定要" + str + "视频专题：" + row.title + "吗？",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          freezeThaw([{ key: "id", val: this.row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取专题已有视频数据
    getVideo(row) {
      if (!this.checked)
        return this.$message({
          message: "整理数据中，请等待10s后再试",
          type: "warning",
        });
      this.row = row;
      this.dialogVideo = true;
      this.videoTitle = "添加视频到专题 >>> " + row.title;
      getVideo([{ key: "id", val: this.row.id }])
        .then((res) => {
          if (res.code == 0) {
            this.checked = false;
            for (let i = 0; i < res.data.length; i++) {
              let is = this.videoArr.indexOf(
                this.videoArr.filter((data) => data.id == res.data[i])[0]
              );
              if (is != -1) {
                this.videoId.push(
                  this.videoArr.filter((data) => data.id == res.data[i])[0]
                );
                this.videoArr[
                  this.videoArr.indexOf(
                    this.videoArr.filter((data) => data.id == res.data[i])[0]
                  )
                ].checked = true;
              }
            }
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加视频到已有视频列表
    addVideoList(data) {
      let index = this.videoArr.indexOf(data);
      this.videoId.push(data);
      this.videoArr[index].checked = true;
    },
    //移除视频出已有视频列表
    delVideoList(data) {
      let index = this.videoId.indexOf(data);
      nextTick("checked", this.videoId[index]);
      let obj = this.videoId[index];
      this.videoId.splice(index, 1);
      this.videoArr[this.videoArr.indexOf(obj)].checked = false;
    },
    // 清空
    emptyVideoList() {
      for (let i = 0; i < this.videoId.length; ) {
        nextTick("checked", this.videoId[0]);
        let obj = this.videoId[0];
        this.videoId.splice(0, 1);
        this.videoArr[this.videoArr.indexOf(obj)].checked = false;
      }
      this.checked = true;
    },
    // 添加视频下拉框改变时
    changeType() {
      console.log(this.type);
    },
    //更新专题视频数据
    editVideo() {
      let arr = [];
      this.videoId.forEach((index) => {
        arr.push(index.id);
      });
      editVideo([
        { key: "id", val: this.row.id },
        { key: "videoId", val: arr.join(",") || 0 },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.data, type: "success" });
            this.dialogVideo = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //关闭弹窗清空数据
    closeDialog() {
      this.dialogArr = {
        title: "", //标题
        weight: 0, //权重
        description: "", //简介
        icon: "", //图标地址
        iconUrl: "", //图标完整地址
        url: "", //图片地址
        imgUrl: "", //图片完整地址
      };
      this.row = []; //清空选中数据
      this.subject = []; //清空选中的精选
      this.emptyVideoList();
      this.pages.page = 1;
    },
    //获取精选下所有视频数据并且打开弹窗
    getVideoEdition(row) {
      this.subject = row;
      this.$message({ message: "获取视频数据中~" });
      getVideoEdition([{ key: "id", val: row.id }])
        .then((res) => {
          if (res.code == 0) {
            this.videoArrTitle = `视频列表(精选：${row.title})`;
            this.videoData = res.data;
            this.videoArrDialog = true;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除精选下某个视频数据
    removeEditionVideo(row) {
      this.$confirm("你确定要移除视频：" + row.title + "吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeEditionVideo([
            { key: "id", val: this.subject.id },
            { key: "video_id", val: row.id },
          ])
            .then((res) => {
              if (res.code == 0) {
                this.getVideoEdition();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createImg(file,w,h,callback){
      var img = document.createElement("img")
      img.width = w
      img.height = h
      img.src = URL.createObjectURL(file)
      setTimeout(()=>{
        var canvas = document.createElement("canvas")
        canvas.width = w
        canvas.height = h
        canvas.getContext('2d').drawImage(img,0,0,canvas.width,canvas.height)
        callback(this.base64ToFile(canvas.toDataURL("image/png"),(new Date()).toTimeString() + ".png"))
      },200)
    },
    //上传图片
    upload(param) {
      this.createImg(param.file,80,80,(file)=>{
        this.$message({ message: "上传中请稍后", type: "success" });
        upload({ file:file, name: "video" })
            .then((res) => {
              if (res.code == 0) {
                this.$message({ message: res.msg, type: "success" });
                this.dialogArr.url = res.data.filename;
                this.dialogArr.imgUrl = res.data.path;
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
      })
    },
    base64ToFile(urldata,filename){
      let arr = urldata.split(",");
      let mine = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);// 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--){
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia],filename,{type:mine})
    },
    //上传图标
    uploadIcon(param) {
      this.createImg(param.file,375,170,(file)=>{
        this.$message({ message: "上传中请稍后", type: "success" });
        upload({ file: file, name: "video",isencode:"true" })
            .then((res) => {
              if (res.code == 0) {
                this.$message({ message: res.msg, type: "success" });
                this.dialogArr.icon = res.data.filename;
                this.dialogArr.iconUrl = res.data.path;
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //放大图片功能
    amplification(url) {
      this.src = url;
      this.srcList = [];
      this.srcList.push(url);
      setTimeout(() => {
        document.getElementsByClassName("bigimg")[0].childNodes[0].click();
      }, 50);
    },
    handleSizeChange(val) {
      this.pages.rows = val;
    },
    handleCurrentChange(val) {
      this.pages.page = val;
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
/* 图片上传部分 */
.form_box >>> .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.form_box >>> .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.form_box >>> .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.form_box >>> .avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.form_box >>> .note {
  margin-left: 30%;
}
.big {
  cursor: pointer;
}
.mouse {
  cursor: pointer;
}
.pages {
  height: 30px;
  line-height: 30px;
  margin: 10px 0;
  float: right;
}
.selecteds {
  width: 400px;
  height: 536px;
  float: right;
  border: 1px solid #ccc;
}
.selectedsHerder {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  background-color: #eee;
  padding: 0 20px;
}
.selectedsEmpty {
  float: right;
  color: #409eff;
  cursor: pointer;
}
</style>
